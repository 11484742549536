.img-pizza-config {
    width: 125px;
}

.img-portion {
    width: 80px;
}

.space-pizza-table {
    padding-top: 400px;
}

.pizza-selected {
    position: absolute;
    top: 20px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
}

.table-pizza {
    top: 20px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
}

.size-image {
    width: 350px;
}

.active-pizza-left {
    border: 1px solid #FF921C;
    border-radius: 30px;
    animation-name: movepizzaleft;
    animation-duration: 1.1s;
    position: relative;
}

.active-pizza-right {
    border: 1px solid #FF921C;
    border-radius: 30px;
    animation-name: movepizzaright;
    animation-duration: 1.1s;
    position: relative;
}

@keyframes movepizzaright {
    0% {
        bottom: 10px;
        opacity: 0.8;
        transform: rotate(30deg);
    }

    25% {
        transform: rotate(-30deg);
        right: 50px;
    }

    50% {
        transform: rotate(30deg);
        right: 80px;
    }

    75% {
        transform: rotate(-30deg);
        right: 90px;
    }

    100% {
        bottom: 400px;
        right: 100px;
        display: none;
        opacity: 0;
    }
}

@keyframes movepizzaleft {
    0% {
        bottom: 10px;
        opacity: 0.8;
        transform: rotate(30deg);
    }

    25% {
        transform: rotate(-30deg);
        left: 50px;
    }

    50% {
        transform: rotate(30deg);
        left: 80px;
    }

    75% {
        transform: rotate(-30deg);
        left: 90px;
    }

    100% {
        bottom: 400px;
        left: 100px;
        display: none;
        opacity: 0;
    }
}

.selected-pizza {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.selected-pizza-item-1 {
    width: 135px;
    height: 160px;
    position: absolute;
    top: 62px;
    right: 34px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transform: rotate(-112deg);
    transition: 1s;
}

.selected-pizza-item-2 {
    width: 135px;
    height: 160px;
    position: absolute;
    top: 19px;
    right: 82px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transform: rotate(-155deg);
    transition: 1s;
}

.selected-pizza-item-3 {
    width: 135px;
    height: 155px;
    position: absolute;
    top: 18px;
    left: 66px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transform: rotate(156deg);
    transition: 1s;
}

.selected-pizza-item-4 {
    width: 130px;
    height: 160px;
    position: absolute;
    top: 66px;
    left: 27px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transform: rotate(110deg);
    transition: 1s;
}

.selected-pizza-item-5 {
    width: 135px;
    height: 160px;
    position: absolute;
    bottom: 60px;
    left: 30px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transform: rotate(68deg);
    transition: 1s;
}

.selected-pizza-item-6 {
    width: 135px;
    height: 160px;
    position: absolute;
    bottom: 12px;
    left: 79px;
    background-repeat: no-repeat;
    background-size: 100%;
    transform: rotate(21deg);
}

.selected-pizza-item-7 {
    width: 135px;
    height: 155px;
    position: absolute;
    bottom: 25px;
    right: 70px;
    background-repeat: no-repeat;
    background-size: 100%;
    transform: rotate(-29deg);
}

.selected-pizza-item-8 {
    width: 135px;
    height: 160px;
    position: absolute;
    bottom: 71px;
    right: 30px;
    background-repeat: no-repeat;
    background-size: 100%;
    transform: rotate(-74deg);
}

.divider-pizza {
    border-top: 2px solid rgba(0, 0, 0, .4)
}

.shadow {
    width: 100%;
    /* height: 10px; */
    /* background-color: red;
    -webkit-box-shadow: 6px 0px 40px -7px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 6px 0px 40px -7px rgba(0, 0, 0, 0.75);
    box-shadow: 6px 0px 40px -7px rgba(0, 0, 0, 0.75); */

}