.making-pizza {
    border: 1px solid #ccc;
    border-radius: 20px;
}

.img-arma {
    width: 40px;
    margin-right: 10px;
}

.img-flecha {
    width: 15px;
}

.img-bolsa {
    width: 25px;
}

.text-orange {
    color: orange;
}

.footer-pizza {
    background-color: white;
    -webkit-box-shadow: 2px -15px 15px -13px rgba(140, 140, 140, 0.72);
    -moz-box-shadow: 2px -15px 15px -13px rgba(1400, 140, 140, 0.72);
    box-shadow: 2px -15px 15px -13px rgba(140, 140, 140, 0.72);
    padding-top: 8px;
    padding-bottom: 10px;
}

.div-totales {
    background-color: white;
    -webkit-box-shadow: -5px 1px 8px 1px rgba(140, 140, 140, 0.73);
    box-shadow: -5px 1px 8px 1px rgba(140, 140, 140, 0.73);
    border-radius: 20px;
    padding: 8px;
}

.div-bolsa {
    width: 50px;
    height: 50px;
    background-color: white;
    border: 1px solid #ccc;
    -webkit-box-shadow: -3px 8px 12px -3px rgba(140, 140, 140, 0.66);
    box-shadow: -3px 8px 12px -3px rgba(140, 140, 140, 0.66);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-grey {
    color: #696969;
}