.background-network{
    background-image: url(../../images/backgroun_network_web.png); 
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.follow_network{
    background-image: url(../../images/follow_network.png);
    background-repeat: no-repeat; 
    background-size: 100%;
    width: 100%;
    height: 100px;
}

.img-logo{
    width: 150px;
    height: 150px;
}


@media (min-width: 320px) and (max-width: 400px) {
    .background-network{
        background-image: url(../../images/background_network_movil.png); 
    }   
    .follow_network{
        background-image: url(../../images/follow_network_movil.png); 
        background-repeat: no-repeat; 
        background-size: 100% 100%;
    }   
}